import {Address} from '../modules/types';

/**
 * Use this to distinguish undefined or null value from other Boolean Falsable values like 0, [], ''
 *
 * For example :
 * ```
 * const el = element ?? 33;
 * // If that particular case, we cannot distring
 * ```
 *
 * @param element
 * @returns true if undefined or null.
 */
export const isUorN = (element: any) => element == null;

export const anyFunction = (..._args: any[]) => undefined;

export const formatTimeRange = (from?: string, to?: string) => {
	if (from && to) {
		return `${from} - ${to}`;
	}
	if (from) {
		return from;
	}
	return to;
};

export const formatFullAddress = (address?: Address) => {
	let fullAdress = address.address1;

	if (address.address2) {
		fullAdress = `${fullAdress} ${address.address2}`;
	}
	if (address.city) {
		fullAdress = `${fullAdress} ${address.city}`;
	}
	if (address.region) {
		fullAdress = `${fullAdress}, ${address.region}`;
	}
	if (address.postalCode) {
		fullAdress = `${fullAdress}, ${address.postalCode}`;
	}
	return fullAdress;
};

export const formatCurrency = (num?: number) => {
	return `${num.toLocaleString('fr-FR', {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2
	})} €`;
};

export const roundValue = (value: number, precision: number = 3): number => {
	return Number.parseFloat(Math.round(value).toPrecision(precision));
};

export const roundToThousands = (num: number): number => {
	const _digits = Math.floor(Math.log10(num));
	const _precision = _digits <= 3 ? 0 : 1;
	const _powered = 10 ** (_digits - _precision);
	const _signif = 10 ** (_digits - 3 - _precision);
	return Math.round(num / _powered) * _signif;
};

export const round = (num: number, significant: number): number => {
	if (num < 1) {
		const _powered = 10 ** significant;
		return Math.round(num * _powered) / _powered;
	}

	const _digits = Math.floor(Math.log10(num)) + 1;
	const _powered = 10 ** (_digits - significant);
	return Math.round(num / _powered) * _powered;
};

/*
 * Merges two arrays without duplicating values.
 * Predicate is used to compare items
 */
export const mergeArrays = (
	a: Array<any>,
	b: Array<any>,
	predicate = (a: any, b: any) => a === b
) => {
	const c = [...a]; // copy to avoid side effects
	b.forEach(bItem =>
		c.some(cItem => predicate(bItem, cItem)) ? null : c.push(bItem)
	);
	return c;
};
