/**
 * Checks if string is not null, undefined and not empty.
 *
 * @param str
 * @returns boolean
 */
export const definedAndNotEmpty = (str?: string) =>
	str !== null && str !== undefined && str.length !== 0;

/**
 * Checks if string looks like an email.
 *
 * @param str
 * @returns true is str looks like and email.
 */
export const looksLikeAnEmail = (str: string) =>
	definedAndNotEmpty(str) && str.includes('@');

/**
 * Ensures that str is never undefined or null.
 * (replace with emppty string in that case)
 */
export const strOrEmpty = (str?: string): string => {
	if (definedAndNotEmpty(str)) {
		return str;
	}

	return '';
};

export const addSpaceAfterThreeDigits = (numberString: any) => {
	if (!numberString) {
		return numberString;
	}

	// Remove all non-digit characters except points and commas
	const cleanedNumber = numberString.replace(/[^0-9.,]/g, '');

	// Replace commas with points to have a consistent decimal separator
	const normalizedNumber = cleanedNumber.replace(/,/g, '.');

	// Add a space after every three digits
	const formattedNumber = normalizedNumber.replace(
		/\B(?=(\d{3})+(?!\d))/g,
		' '
	);
	return formattedNumber;
};
export const addZerosToNumber = (numberString: any) => {
	if (!numberString) {
		return numberString;
	}

	// Remove all non-digit characters except points and commas
	const cleanedNumber = numberString.replace(/[^0-9.,]/g, '');

	// Replace commas with points to have a consistent decimal separator
	const normalizedNumber = cleanedNumber.replace(/,/g, '.');

	// Convert the number to a floating-point number
	const numericValue = parseFloat(normalizedNumber);

	// Check if the conversion was successful and the result is a number
	if (!isNaN(numericValue)) {
		// Add two zeros to the number
		const numberWithZeros = (numericValue * 100).toFixed(2);
		return numberWithZeros;
	} else {
		// Return the original string if the conversion fails
		return numberString;
	}
};
